.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-y: auto;
}

.redColor {
  color: var(--color-red);
}

.orangeColor {
  color: var(--color-orange);
}
